<template>
    <div class="profile_lifecycles profile_notes">
        <div class="title">
            <h3>{{ params.duration }}</h3>
            <div class="right_side">
                <date-filter v-model="params" />
            </div>
        </div>
        <ul class="lifecycles_tags">
            <li class="toggle_btn" @click="show_more($event)"><i class="fas fa-chevron-down"></i></li>
            <li :class="{ active : lifecycleSubjectList.length == (params.subject.split(',')).length }" @click="getAllLifecycle()">
                <img :src="require('@/assets/images/all.svg')">All
            </li>
            <li @click="filterBySubject(subject)" :class="{ active : params.subject.includes(subject) }" v-for="(subject, i) of lifecycleSubjectList" :key="i">
                <img :src="getTemplate(subject)">{{ filterSubject(subject) }}
            </li>
        </ul>
        <div class="lifecycle">
            <ul style="margin-bottom: 20px" class="infinite-wrapper">
                <li v-for="(lifecycle, key) of lifecycles" :key="key">
                    <span class="icon"><img :src="getSubjectClass(lifecycle.subject)"></span>
                    <h4>
                        {{ filterSubject(lifecycle.subject) }}
                        <label class="tag" :class="getSourceClass(lifecycle.source)">
                            {{ lifecycle.source }}
                        </label>
                        <span>{{ moment.utc(lifecycle.updated_at).local().fromNow() }}</span>
                    </h4>
                    <div class="info">
                        <span v-html="lifecycle.description"></span>
                        <span class="date">{{ `${moment.utc(lifecycle.updated_at).local().format('ll | LT')}` }}</span>
                    </div>
                </li>
                <infinite-loading class="infinite-loading" @infinite="handleLifecycle" target=".lifecycle" :distance="4" :identifier="resetInfiniteWatcher" />
            </ul>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const DateFilter = defineAsyncComponent(() => import('@/components/DateFilter'))

    import moment from 'moment'
    import InfiniteLoading from 'v3-infinite-loading'

    export default{
        name: 'Tab Profile Lifecycles',

        data () {
            return {
                params: {
                    duration: 'Last 7 Days',
                    contact_id: '',
                    page: 1,
                    subject: '',
                    start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                    last_page: 1,
                    per_page: 10,
                },
                moment,
                lifecycles: [],
                loader: false,
                state: undefined,
                resetInfiniteWatcher: 0,
            }
        },

        props: {
            contact: Object,
        },

        components:{
            DateFilter,
            InfiniteLoading,
        },

        watch: {
            'params.start_date' () {
                const vm = this;
                console.log('here');
                vm.lifecycles       = [];
                vm.params.page      = 1;
                vm.params.last_page = 1;
                vm.resetInfiniteWatcher++;
                vm.handleLifecycle(undefined, true);
            },
        },

        computed: {
            ...mapState({
                lifecycleSubjectList: state => state.contactModule.lifecycleSubjectList,
            }),
        },

        mounted () {
            const vm = this;

            vm.lifecycles = [];
            vm.getLifecycleSubject(vm.contact.id).then((result) => {
                vm.resetInfiniteWatcher++;
                vm.resetForm();
                vm.handleLifecycle(undefined, true);
            });
        },

        methods:{
            ...mapActions({
                getLifecycle: 'contactModule/getLifecycle',
                getLifecycleSubject: 'contactModule/getLifecycleSubject',
            }),

            filterBySubject (subject = '') {
                const vm = this;

                vm.params.subject   = subject;
                vm.params.page      = 1;
                vm.params.last_page = 1;
                vm.lifecycles       = [];
                vm.resetInfiniteWatcher++;
                vm.handleLifecycle(undefined, true);
            },

            resetForm () {
                const vm = this;

                vm.params = {
                    contact_id: vm.contact.id ? vm.contact.id : '',
                    duration: 'Last 7 Days',
                    page: 1,
                    subject: vm.lifecycleSubjectList.join(','),
                    start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                    last_page: 1,
                }
            },

            handleLifecycle ($state, force = false) {
                const vm = this;

                if ($state) {
                    vm.state = $state;
                }

                if ((vm.params.page <= vm.params.last_page) || force) {
                    if (!vm.loader) {
                        vm.loader = true;
                        vm.params.contact_id = vm.contact.id;

                        vm.getLifecycle(vm.params).then((result) => {
                            if (result.data && result.data.length) {
                                vm.params.last_page = result.last_page;
                                vm.params.page = result.current_page + 1;

                                vm.lifecycles.push(...result.data);
                                vm.loader = false;

                                setTimeout(function () {
                                    if (vm.state != undefined) {
                                        vm.state.loaded();
                                    }
                                }, 1000);
                            } else {
                                if (vm.state != undefined) {
                                    vm.state.complete();
                                }

                                vm.loader = false;
                            }
                        });
                    }
                } else {
                    if (vm.state != undefined) {
                        vm.state.complete();
                    }
                }
            },

            getSubjectClass (subject) {
                if (subject == 'Profile') {
                    return require('@/assets/images/profile.svg');
                } else if (subject == 'Broadcasts') {
                    return require('@/assets/images/broadcast.svg');
                } else if (subject == 'Journey') {
                    return require('@/assets/images/journey-alt.svg');
                } else if (subject == 'Notes') {
                    return require('@/assets/images/note.svg');
                } else if (subject == 'Playbooks') {
                    return require('@/assets/images/playbook-alt.svg');
                } else if (subject == 'Forms') {
                    return require('@/assets/images/form-alt.svg');
                } else if (subject == 'Rewards') {
                    return require('@/assets/images/gift.svg');
                } else if (subject == 'Progress Tracking') {
                    return require('@/assets/images/client.svg');
                } else if (subject == 'Habit Tracking') {
                    return require('@/assets/images/drip.svg');
                } else if (subject == 'Sequences') {
                    return require('@/assets/images/sequence-alt.svg');
                } else if (subject.includes('Contact')) {
                    return require('@/assets/images/assign.svg');
                } else if(subject == 'Emails') {
                    return require('@/assets/images/email.svg');
                } else if(subject == 'Tags') {
                    return require('@/assets/images/tags-alt.svg');
                } else if(subject == 'Member ID') {
                    return require('@/assets/images/member-id.svg');
                } else if(subject == 'Temp') {
                    return require('@/assets/images/temperature-alt.svg');
                } else if(subject == 'Page') {
                    return require('@/assets/images/page-alt.svg');
                } else if(subject == 'Pipeline' || subject == 'Pipelines') {
                    return require('@/assets/images/pipeline-alt.svg');
                } else if(subject == 'Template' || subject == 'Templates') {
                    return require('@/assets/images/template-alt.svg');
                } else if(subject == 'Workout' || subject == 'Workouts') {
                    return require('@/assets/images/kettlebell-alt.svg');
                } else if(subject == 'Workout Plan' || subject == 'Workout Plans') {
                    return require('@/assets/images/workout-plan-alt.svg');
                } else {
                    return require('@/assets/images/client.svg');
                }
            },

            getUpdateTime (time) {
                const updateTime = moment.utc(time).local().fromNow()

                return updateTime;
            },

            getCreateTime (time) {
                const createTime = `${moment.utc(time).local().format('ll')} | ${moment.utc(time).local('true').format('hh:mm A')}`

                return createTime;
            },

            getTemplate (subject) {
                if (subject == 'Tags') {
                    return require('@/assets/images/tags-alt.svg');
                } else if (subject == 'Broadcasts') {
                    return require('@/assets/images/broadcast.svg');
                } else if (subject == 'Journey') {
                    return require('@/assets/images/journey-alt.svg');
                } else if (subject == 'Profile') {
                    return require('@/assets/images/picture-alt.svg');
                } else if (subject == 'Page') {
                    return require('@/assets/images/page-alt.svg');
                } else if (subject == 'Emails') {
                    return require('@/assets/images/email.svg');
                } else if (subject == 'Forms') {
                    return require('@/assets/images/form-alt.svg');
                } else if (subject == 'Playbooks') {
                    return require('@/assets/images/playbook-alt.svg');
                } else if (subject == 'Sequences') {
                    return require('@/assets/images/sequence-alt.svg');
                } else if (subject == 'Notes') {
                    return require('@/assets/images/note.svg');
                } else if (subject == 'Pipeline') {
                    return require('@/assets/images/pipeline-alt.svg');
                } else if (subject == 'Progress Tracking') {
                    return require('@/assets/images/client.svg');
                } else if (subject == 'Habit Tracking') {
                    return require('@/assets/images/drip.svg');
                } else if (subject == 'Member ID') {
                    return require('@/assets/images/member-id.svg');
                } else if (subject == 'Rewards') {
                    return require('@/assets/images/gift.svg');
                } else if (subject == 'Copy Contact') {
                    return require('@/assets/images/copy.svg');
                } else if (subject == 'Assign Contact') {
                    return require('@/assets/images/assign.svg');
                } else if (subject == 'Profile Picture') {
                    return require('@/assets/images/profile.svg');
                } else if (subject == 'Templates') {
                    return require('@/assets/images/template-alt.svg');
                } else if (subject == 'Workout ') {
                    return require('@/assets/images/kettlebell-alt.svg');
                } else if (subject == 'Workout Plan') {
                    return require('@/assets/images/workout-plan-alt.svg');
                } else if (subject == 'Sms') {
                    return require('@/assets/images/sms-circle.svg');
                }
            },

            getSourceClass (source) {
                if (source == 'OPENED') {
                    return 'opened'
                } else if (source == 'ADD' || source == 'ENABLED' || source == 'DELIVERED' || source == 'MESSAGE SEND' || source == 'ASSIGN') {
                    return 'success'
                }
            },

            getAllLifecycle () {
                const vm = this;

                vm.params.subject = vm.lifecycleSubjectList.join(',');

                vm.params.page      = 1;
                vm.params.last_page = 1;
                vm.lifecycles       = [];
                vm.resetInfiniteWatcher++;
                vm.handleLifecycle(undefined, true);
            },

            filterSubject (value) {
                if (value.toLowerCase() == 'workoutplan') {
                    return 'Workout Programs';
                } else if (value.toLowerCase() == 'workout plan') {
                    return 'Workout Programs';
                } else if (value.toLowerCase() == 'sms') {
                    return 'SMS';
                } else if (value.toLowerCase() == 'progress tracking') {
                    return 'Client Tracking';
                } else if (value.toLowerCase() == 'page') {
                    return 'Pages';
                } else if (value.toLowerCase().trim() == 'workout') {
                    return 'Workouts';
                }

                return value;
            },

            show_more(e){
                const vm = this;
                let el = e.currentTarget.closest('.lifecycles_tags');

                if (el.classList.contains('show')){
                    el.classList.remove('show');
                 } else {
                    el.classList.add('show');
                }
            }
        }
    }
</script>

<style scoped>
    .lifecycle {
        flex: 1;
        overflow-y: auto;
        padding: 15px 25px;
        background: #fff;
        border-top: 1px solid #eee;
        border-radius: 0 0 8px 8px;
    }
    .lifecycle::-webkit-scrollbar {
        width: 4px;
    }
    .lifecycle:hover::-webkit-scrollbar-thumb {
        background-color: #e4e4e4;
        border-radius: 2px;
    }
    .profile_lifecycles .lifecycles_tags li.toggle_btn{
        position: absolute;
        right: 5px;
        top: 5px;
        margin: 0;
        padding: 5px;
        font-size: 10px;
        color: #5a5a5a;
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
        display: none;
    }
    @media(max-width: 768px){
        .profile_lifecycles .lifecycle{
            padding: 15px;
        }
        .profile_lifecycles .lifecycles_tags li.toggle_btn{
            display: block;
        }
        .profile_lifecycles .lifecycles_tags.show li.toggle_btn{
            transform: rotate(-180deg);
        }
        .lifecycles_tags{
            max-height: 50px;
            overflow: hidden;
        }
        .lifecycles_tags.show{
            max-height: max-content;
            overflow: visible;
        }
    }
</style>
